.selected {
  border: 3px solid #007bff; /* Couleur bleue pour indiquer la sélection */
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
}
.photo-container {
  position: relative;
}

.order-indicator {
  position: absolute;
  top: 10px; /* Ajustez la position */
  left: 10px; /* Ajustez la position */
  background-color: #007bff; /* Couleur de fond */
  color: white; /* Couleur du texte */
  border-radius: 50%; /* Pour faire une capsule */
  padding: 5px 10px; /* Espacement autour du texte */
  font-weight: bold; /* Met le texte en gras */
  z-index: 1; /* Pour s'assurer que le badge est au-dessus de l'image */
}
.winner-container {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.order-indicator {
  border-radius: 50%;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}
.order-indicator span {
	font-size: 30px;
  background-color: transparent !important;
}